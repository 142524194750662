import React, { useEffect, useState } from "react";
import { Typography, Alert, Spin, Button, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { purchaseProductsTotal, formatCurrency, formatMBReference } from "utils/helper";
import { payment_mb_post } from "api/payments";
import { order_post } from "api/orders";
import SelectPaymentMethodModal from "../../../Shared/SelectPaymentMethodModal";
import OfflinePaymentInfoAlert from "../../../Shared/OfflinePaymentInfoAlert";

import { utmParams } from "utils/helper";
const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams();
const env = process.env?.ENVIRONMENT?.toLowerCase() || "development";

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const Multibanco = ({ productData, data, actionHandler, step }) => {
  const [loading, setLoading] = useState(true);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState({ visible: false });
  const [waitForPayment, setWaitForPayment] = useState(false);
  const [paymentDataResponse, setPaymentDataResponse] = useState({});
  const [errorFetching, setErrorFetching] = useState(false);
  const [paid, setPaid] = useState(false);
  const status = data?.status?.action;

  useEffect(() => {
    if (status === "prepare-payment") getMBReference();
  }, []);

  useEffect(() => {
    const status = data?.status?.action;
    if (status === "paid") {
      setPaid(true);
    }
  }, [data]);

  const createOrder = (payload) => {
    return new Promise((resolve) => {
      try {
        order_post(payload)
          .then((res) => resolve({ status: true, data: res }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const getMBReference = async () => {
    if (data?.identification?.email === undefined || data?.identification?.email === null) {
      return;
    }
    setLoading(true);
    const payload = {
      buyer: data.identification,
      products: data.purchase_products,
      purchase_id: data.purchase_id,
      main_product: data.main_product,
      coupon: data.coupon,
      pathname: window.location.pathname,
      origin: window.location.origin,
      affiliate_id: data.affiliate_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };

    // Disable hability to change quantity
    actionHandler("qty_locked", true);

    const order = await createOrder({ ...payload, method: "mb", source: "checkout" });
    if (!order.status) {
      setLoading(false);
      setErrorFetching(true);
      return;
    }

    payment_mb_post(payload)
      .then((response) => {
        setPaymentDataResponse({
          entity: response.data.entity,
          reference: response.data.reference,
          value: response.data.value,
        });

        if (env !== "production") {
          console.log("Multibanco Provider", response.data.provider);
        }

        setErrorFetching(false);
        setWaitForPayment(true);
        actionHandler("update_status", {
          action: "wait-for-payment",
          data: {
            method_id: "MB",
            entity: response.data.entity,
            reference: response.data.reference,
            value: response.data.value,
          },
        });
      })
      .catch((error) => {
        //console.log("error", error);
        setErrorFetching(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //console.log(data?.purchase_products, purchaseProductsTotal(data?.purchase_products));

  return (
    <div className="multibanco-container">
      <div className="payment-data-container">
        {paid === false && (
          <Spin spinning={loading} tip="A gerar referência multibanco...">
            {loading && <div className="loading"></div>}

            {errorFetching && !loading && (
              <div className="error">
                <Alert
                  message="Oops, ocorreu um erro ao gerar a referência multibanco."
                  description="Não foi possível gerar a referência multibanco para a tua compra. Tente novamente dentro de alguns segundos."
                  type="error"
                  showIcon
                />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={() => getMBReference()}>
                    Tentar novamente
                  </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="link" onClick={() => setSelectPaymentMethod({ visible: true })}>
                    Selecionar outro método de pagamento
                  </Button>
                </div>
              </div>
            )}
            {waitForPayment && !errorFetching && (
              <>
                <div className="payment-data">
                  <div className="payment-text">
                    Entidade: <strong>{paymentDataResponse?.entity}</strong>
                  </div>
                  <div className="payment-text">
                    Referência: <strong>{formatMBReference(paymentDataResponse?.reference)}</strong>
                  </div>
                  <div className="payment-text">
                    Montante: <strong>{formatCurrency(paymentDataResponse?.value)}</strong>
                  </div>
                </div>
                <div className="payment-instructions">
                  O pagamento por Referência Multibanco pode ser efetuado em uma ATM ou via Homebanking.
                </div>
              </>
            )}

            {waitForPayment && !errorFetching && (
              <div className="payment-loading">
                <Alert
                  message={
                    <div className="payment-loading-alert">
                      <LoadingOutlined /> A aguardar pagamento...
                    </div>
                  }
                  type="warning"
                />
                <OfflinePaymentInfoAlert />
              </div>
            )}
          </Spin>
        )}
      </div>
      {paid === true && (
        <Result
          status="success"
          title="Pagamento Concluído"
          subTitle="Parabéns, a tua compra foi efetuada com sucesso!"
        />
      )}

      <SelectPaymentMethodModal
        data={data}
        actionHandler={actionHandler}
        visible={selectPaymentMethod.visible}
        close={() => setSelectPaymentMethod({ visible: false })}
      />
    </div>
  );
};

export default Multibanco;
