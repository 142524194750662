import React from "react";
import { Button, Result } from "antd";

const ProductOFferNotFound = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        status="404"
        title="Oops!"
        subTitle="O produto ou plano de pagamento foi desativado e já não está disponível para venda."
      />
    </>
  );
};

export default ProductOFferNotFound;
