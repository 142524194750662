import React, { useState, useEffect } from "react";
import { Drawer, Button, Form, Input, Select, Spin, Row, notification, Alert, Modal, Col, Tooltip } from "antd";
import { PhoneInput, defaultCountries, parseCountry } from "react-international-phone";
import { CheckCircleOutlined } from "@ant-design/icons";
import { vatid_get } from "../../../api/validations";
import { countries_fiscal_get } from "../../../api/actions";
import postalCodes from "../../../config/postal-codes.json";
import phoneCountriesAllowed from "../../../config/phone_countries.json";
import { IsNullUndefinedEmpty } from "../../../utils/helper";
import useMediaQuery from "use-media-antd-query";
import { validatePhoneNumber } from "../../../utils/validations";
import { sales_billing_data_post } from "../../../api/sales";
import { DoubleDataConfirmationContent, NoVatIDContent } from "../BillingFormHelper";
const { Option } = Select;
const { confirm } = Modal;

const BillingDrawer = ({ visible, close, billing, token, purchaseId }) => {
  const [form] = Form.useForm();
  //  const [data, setData] = useState({});
  const [isLoadingForm, setIsLoadingForm] = useState({ loading: true, message: "A carregar dados..." });
  const [isLoadingVatId, setIsLoadingVatId] = useState(false);
  const [errorLoadingVatIdData, setErrorLoadingVatIdData] = useState(false);
  const [phoneNumberData, setPhoneNumberData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [vatIdValidationData, setVatIdValidationData] = useState(null);

  const postalCodeFormat = postalCodes.find((p) => p.ISO === selectedCountry);
  const regions = countries?.find((c) => c.value === selectedCountry)?.regions || [];

  const colSize = useMediaQuery();
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);
  console.log("BillingDrawer -> billing -> data", billing);

  const allowedCountriesList = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return phoneCountriesAllowed.includes(iso2);
  });

  useEffect(() => {
    init();

    return () => {
      setCountries([]);
      setPhoneNumberData(null);
      setVatIdValidationData(null);
    };
  }, [visible]);

  const init = async () => {
    try {
      const response = await dbGetCountriesFiscal();

      if (!response.status) {
        Modal.error({
          title: "Erro ao carregar os países...",
          content:
            "Pode ser um erro temporário. Se o erro persistir entra em contacto com o suporte. Vamos recarregar a página para tentar novamente.",
          okText: "Recarregar a página",
          onOk() {
            window.location.reload();
          },
        });
        setIsLoadingForm({ loading: false, message: "" });
        return;
      }

      setCountries(response.data);
      form.setFieldsValue(billing);
      setSelectedCountry(billing?.country || "PT");
      setSelectedRegion(billing?.region || "PT");
      setPhoneNumberData({ phone: billing?.phone, error: false });
      setIsLoadingForm({ loading: false, message: "" });

      // Error handling
    } catch (error) {
      console.log(error);
    }
  };

  const dbGetCountriesFiscal = async () => {
    return new Promise((resolve) => {
      try {
        countries_fiscal_get()
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const validateVatId = async (vatid) => {
    return new Promise((resolve) => {
      setIsLoadingVatId(true);
      vatid_get(vatid)
        .then((res) => {
          resolve({ status: true, data: res.data });
          setVatIdValidationData(res.data);
          setErrorLoadingVatIdData(false);
        })
        .catch((err) => {
          resolve({ status: false, data: err });
          setVatIdValidationData(null);
          setErrorLoadingVatIdData(true);
        })
        .finally(() => {
          setIsLoadingVatId(false);
        });
    });
  };

  const unconfirmedVatId = () => {
    return new Promise((resolve) => {
      confirm({
        title: "Número de Contribuinte",
        content: "Não conseguimos validar o número de contribuinte. Continuar mesmo assim?",
        okText: "Sim",
        okType: "primary",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const noVatId = () => {
    return new Promise((resolve) => {
      confirm({
        width: 500,
        title: "Número de Contribuinte",
        content: <NoVatIDContent />,
        okText: "Sim, confirmo",
        okType: "primary",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const doubleDataConfirmation = () => {
    return new Promise((resolve) => {
      confirm({
        width: 500,
        title: "Confirmas os dados introduzidos?",
        content: <DoubleDataConfirmationContent />,
        okText: "Sim, confirmo os dados",
        okType: "primary",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const onConfirmBillingData = async (values) => {
    setIsLoadingForm({ loading: true, message: "A atualizar os dados de faturação..." });
    const response = await updateBillingData(values);
    if (!response.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível atualizar os dados de faturação. Verifica os dados, e tenta novamente.",
        duration: 7,
      });
      setIsLoadingForm({ loading: false, message: "" });
      return;
    }

    notification.success({
      message: "Dados de Faturação Atualizados",
      description: "Os teus dados de faturação foram atualizados com sucesso.",
    });
    setIsLoadingForm({ loading: false, message: "" });
    close(true);
  };

  const updateBillingData = (data) => {
    return new Promise((resolve) => {
      data.token = token;
      data.purchase_id = purchaseId;
      try {
        sales_billing_data_post(data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };
  const onFinishBilling = async (values) => {
    const vatid = values?.vatid?.trim();
    values.country = selectedCountry;
    values.region = selectedRegion;

    if (values.email !== billing.email) {
      Modal.warning({
        title: "Alteração de E-mail",
        content: "O e-mail foi alterado. Para alterar o e-mail, por favor contacte o suporte.",
        okText: "OK",
      });
      return;
    }

    if (errorLoadingVatIdData && !IsNullUndefinedEmpty(vatid)) {
      const e = await unconfirmedVatId();
      if (!e) return;
    }

    if (IsNullUndefinedEmpty(vatid)) {
      const r = await noVatId();
      if (!r) return;
    }

    // Validate phone number and get validation object (isValid, title, message)
    const phoneValidation = validatePhoneNumber(
      phoneNumberData.phone,
      phoneNumberData,
      setPhoneNumberData,
      false // Required for billing
    );

    if (phoneValidation.isValid === false) {
      Modal.warning({
        title: phoneValidation.title,
        content: phoneValidation.message,
        okText: "OK",
      });
      return;
    }

    const dc = await doubleDataConfirmation(values);
    if (dc) onConfirmBillingData(values);
  };

  return (
    <Drawer
      title="Dados de Faturação"
      visible={visible}
      onClose={close}
      width={isMobileSize === true ? "100%" : 650}
      closable={true}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={close} style={{ marginRight: 8 }}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit" form="billing-form" key="submit">
            Confirmar os dados <CheckCircleOutlined />
          </Button>
        </div>
      }
    >
      <div className="billing-info">
        <Spin spinning={isLoadingForm.loading} tip={isLoadingForm.message}>
          <Form
            id="billing-form"
            className="billing-form"
            disabled={isLoadingForm.loading}
            layout="vertical"
            form={form}
            onFinish={onFinishBilling}
          >
            <Form.Item
              name="name"
              label="Nome ou Empresa"
              rules={[{ required: true, message: "O nome é de preenchimento obrigatório..." }]}
              hasFeedback
            >
              <Input placeholder="O teu nome ou o nome da Empresa" />
            </Form.Item>

            <Form.Item label="Número de Contribuinte">
              <Form.Item
                noStyle
                name="vatid"
                rules={[
                  {
                    validator: async (_, value) => {
                      setVatIdValidationData(null);

                      // If Country isnt Portugal, then dont validate VATID
                      if (billing.country?.toUpperCase() !== "PT") return Promise.resolve();

                      if (value === undefined || value === "" || value === null) {
                        return Promise.resolve();
                      }

                      if (value.length > 0 && value.length < 9 && isNaN(value) === false) {
                        return Promise.reject("Número de contribuinte incompleto.");
                      }
                      if (value.length > 0 && value.length < 9 && isNaN(value) === true) {
                        return Promise.reject("Número de contribuinte inválido.");
                      }

                      const rv = await validateVatId(value, true);
                      if (rv.status) {
                        if (rv.data.status === false) {
                          return Promise.reject(rv.data.message);
                        } else {
                          return Promise.resolve("Número de contribuinte válido!");
                        }
                      } else {
                        return Promise.reject("Não foi possível verificar o número de contribuinte.");
                      }
                    },
                  },
                  {
                    required: false,
                  },
                ]}
                hasFeedback
              >
                <Input
                  className="vatid"
                  placeholder="Número de Contribuinte"
                  loading={isLoadingVatId}
                  onBlur={(e) => {
                    form.validateFields(["vatid"]);
                  }}
                />
              </Form.Item>
              {vatIdValidationData?.data && (
                <div>
                  <Tooltip title="Dados encontrados com base no número de contribuinte indicado.">
                    <small className="text-muted">{vatIdValidationData.data.name}</small>
                  </Tooltip>
                </div>
              )}
            </Form.Item>

            <Form.Item
              name="email"
              label="Endereço de e-mail"
              rules={[
                {
                  required: true,

                  message: "O endereço de e-mail é de preenchimento obrigatório...",
                },
                {
                  type: "email",
                  message: "O endereço de e-mail é inválido...",
                },
              ]}
              hasFeedback
            >
              <Input disabled placeholder="O endereço de e-mail para envio da fatura" />
            </Form.Item>

            <Form.Item name="address" label="Morada" rules={[{ required: false }]} hasFeedback>
              <Input placeholder="Morada" />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="city" label="Cidade" rules={[{ required: false }]} hasFeedback>
                  <Input placeholder="Cidade" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="postal_code" label="Código Postal">
                  <Input
                    className="postal-code"
                    placeholder={postalCodeFormat ? postalCodeFormat?.Format?.replace(/N/gi, "0") : ""}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item noStyle>
              <Form.Item
                className={phoneNumberData?.error === true ? "mb-0" : "mb-1"}
                label="Número de telemóvel"
                name="phone"
                hasFeedback
              >
                <PhoneInput
                  className="internacional-phone-input"
                  value={phoneNumberData?.phone}
                  defaultCountry="pt"
                  placeholder="Número de Telemóvel"
                  countries={allowedCountriesList}
                  preferredCountries={["pt", "es", "fr", "ie", "gb"]}
                  disableDialCodeAndPrefix={true}
                  onChange={(phone, meta) => {
                    setPhoneNumberData({ phone, data: meta, error: false });
                  }}
                />
              </Form.Item>
              {phoneNumberData?.error === true && (
                <Alert
                  message="O número de telemóvel indicado não é válido."
                  type="error"
                  showIcon
                  style={{ marginTop: 10, marginBottom: 15 }}
                />
              )}
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label="País"
                  rules={[{ required: true, message: "País é de preenchimento obrigatório." }]}
                  hasFeedback
                >
                  <Select disabled>
                    {countries?.map((country) => (
                      <Option key={country.value} value={country.value}>
                        {country.portugueseName} ({country.localName})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                {regions.length > 1 && (
                  <Form.Item
                    name="region"
                    label="Região"
                    rules={[{ required: regions.length > 1, message: "Região é de preenchimento obrigatório." }]}
                    hasFeedback
                  >
                    <Select disabled>
                      {regions.map((region) => (
                        <Option key={region.value} value={region.value}>
                          {region.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
          <Alert
            message="Notas Importantes"
            description={
              <>
                <p>
                  Queremos garantir que a tua experi&ecirc;ncia de fatura&ccedil;&atilde;o seja simples e sem
                  complica&ccedil;&otilde;es. Por isso, antes de alterares os teus dados de fatura&ccedil;&atilde;o,
                  tem em conta o seguinte:
                </p>
                <p>
                  1️⃣ <strong>Altera&ccedil;&otilde;es futuras</strong> &ndash; As mudan&ccedil;as nos teus dados de
                  fatura&ccedil;&atilde;o s&oacute; ser&atilde;o aplicadas &agrave;s pr&oacute;ximas faturas. As
                  faturas j&aacute; emitidas n&atilde;o podem ser modificadas.
                </p>
                <p>
                  2️⃣ <strong>Fatura&ccedil;&atilde;o autom&aacute;tica</strong> &ndash; O nosso sistema gera as
                  faturas de forma autom&aacute;tica, por isso certifica-te de que os teus dados est&atilde;o
                  corretos antes de finalizares qualquer altera&ccedil;&atilde;o.
                </p>
              </>
            }
            type="info"
            showIcon={isMobileSize === true ? false : true}
            style={{ marginTop: 10, marginBottom: 15 }}
          />
        </Spin>
      </div>
    </Drawer>
  );
};

export default BillingDrawer;
