import { Select } from "antd";
const { Option } = Select;

export const DoubleDataConfirmationContent = () => (
  <div>
    <p>
      Por favor, verifica se todos os dados est&atilde;o corretos antes de confirmares. Lembra-te de que,
      ap&oacute;s a fatura ser emitida n&atilde;o ser&aacute; poss&iacute;vel fazer altera&ccedil;&otilde;es.
      <br />
      <br />
    </p>
    <p>
      <strong>Podes confirmar que os dados introduzidos est&atilde;o corretos?</strong>
    </p>
  </div>
);

export const NoVatIDContent = () => (
  <div>
    <p>N&atilde;o indicaste um n&uacute;mero de contribuinte.</p>
    <p>
      A fatura ser&aacute; emitida com os teus dados, mas o contribuinte ser&aacute; registado como{" "}
      <strong>CONSUMIDOR FINAL</strong>.
    </p>
    <p>
      <strong>Tens a certeza de que queres continuar?</strong> <br />
      <small style={{ textDecoration: "underline" }}>
        Lembra-te de que, ap&oacute;s a emiss&atilde;o da fatura, n&atilde;o ser&aacute; poss&iacute;vel alterar o
        contribuinte.
      </small>
    </p>
  </div>
);

export const BillingDataFields = ({ fieldTitle, fieldValue }) => {
  return (
    <div className="billing-info-line">
      <div className="billing-info-title">{fieldTitle}:</div>
      <div className="billing-info-value">
        {fieldValue || <span className="billing-info-no-value">Não indicado</span>}
      </div>
    </div>
  );
};

// export const GetCountriesList = ({ countries, onChange }) => {
//   try {
//     if (!countries) return <></>;
//     if (countries.length === 0) return <></>;
//     const Options = countries.map((country) => (
//       <Option key={country.value} value={country.value}>
//         {country.portugueseName} ({country.localName})
//       </Option>
//     ));
//     return <Select onChange={onChange}>{Options}</Select>;
//   } catch (error) {
//     return <></>;
//   }
// };

export const GetRegionsList = ({ countries, country }) => {
  if (country === undefined || country === null) return <></>;
  if (!countries) return <></>;
  if (countries.length === 0) return <></>;
  const regions = countries.find((c) => c.value === country).regions;
  //setRegions(regions);

  return regions.map((region) => (
    <Option key={region.value} value={region.value}>
      {region.name}
    </Option>
  ));
};

const cw = (string) => {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const parseCompanyData = (form, data) => {
  try {
    const _address = data.address.split(", ");
    form.setFieldsValue({ name: cw(data.name) });
    form.setFieldsValue({ address: cw(_address[0]) });
    form.setFieldsValue({ city: cw(_address[1]) });
    form.setFieldsValue({ postal_code: _address[2].split(" ")[0] });
  } catch (error) {}
};
