import React, { useState, useEffect } from "react";

import { Layout, Spin, Row, Col, notification, Modal } from "antd";
import axios from "axios";
import Banner from "../Components/Banner";
import MainContent from "../Components/MainContent";
import SideBar from "../Components/SideBar";
import { useParams, useSearchParams } from "react-router-dom";
import { analytics_create_event_post } from "../api/analytics";
import {
  action_pre_checkout_post,
  action_check_duplicated_post,
  action_check_email_post,
  action_pre_checkout_option_post,
} from "../api/actions";
import ErrorGettingProduct from "../Components/Shared/ErrorGettingProduct";
import ProductNotFound from "../Components/Shared/ProductNotFound";
import ProductOFferNotFound from "../Components/Shared/ProductOFferNotFound";
import ProductUnavailable from "../Components/Shared/ProductUnavailable";
import ProductSalesClosed from "../Components/Shared/ProductSalesClosed";
import AccountSuspended from "../Components/Shared/AccountSuspended";
import AccountClosed from "../Components/Shared/AccountClosed";
import {
  saveOnLocalStorage,
  IsNullUndefinedEmpty,
  NotNullUndefinedEmpty,
  loadLocalData,
  store,
  createPurchaseProductsList,
  isIntegerNumber,
  saveAffiliateId,
  getAffiliateId,
} from "../utils/helper";
import { getPurchaseId, isProductValid, getProduct } from "../utils/api";
import backupCountries from "../config/countries.json";
import { countries_fiscal_get } from "../api/actions";
import { product_metrics_post } from "../api/products";
//import ReactPixel from "react-facebook-pixel";
import { pixelEventPageView, pixelEventInitiateCheckout, pixelEventAddPaymentInfo } from "../utils/pixels";

import { loadFromLocalStorage, validateAllowedCountry, parsePAA } from "../utils/helper";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { utmParams } from "utils/helper";

const { Header, Content } = Layout;

const isWidget = window?.location?.hash?.substr(1) === "widget";

const IndexPage = (props) => {
  let { product_id } = useParams();
  const PRODUCT_ID = product_id || "";
  const [searchParams, setSearchParams] = useSearchParams();

  //const search = window.location.search;
  // const params = new URLSearchParams(search);
  const OFFERS = searchParams.get("offer");
  const LEGACY_PRODUCT_ID = searchParams.get("p") || "";

  const _NAME = searchParams.get("name") || "";
  const _EMAIL = searchParams.get("email") || "";
  const _PHONE = searchParams.get("phone") || "";
  const _VATID = searchParams.get("vatid") || "";
  let _COUPON = searchParams.get("coupon") || "";
  const _QTY = searchParams.get("qty") || 1;
  const _AFFILIATE_ID = parsePAA(searchParams.get("aff") || searchParams.get("paa") || "");

  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams();

  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [errorTypeMessage, setErrorTypeMessage] = useState(null);
  const [step, setStep] = useState(0);
  const [productData, setProductData] = useState(null);
  const [duplicatesSales, setDuplicatesSales] = useState(0);
  const [state, setState] = useState({ status: { action: "identification", data: {} } });

  const [identification, setIdentification] = useState({
    name: _NAME,
    email: _EMAIL,
    phone: _PHONE,
    vatid: _VATID,
  });

  const [countries, setCountries] = useState([]);
  //const [selectedCountry, setSelectedCountry] = useState(undefined);
  //const [selectedRegion, setSelectedRegion] = useState(undefined);

  useEffect(() => {
    //console.log("IndexPage", LEGACY_PRODUCT_ID, PRODUCT_ID);

    // Check if blocked buyer
    // if (store("GET", "blocked-buyer") === "1") {
    //   window.location.href = "/something-went-wrong";
    //   return;
    // }

    if (!IsNullUndefinedEmpty(LEGACY_PRODUCT_ID) && IsNullUndefinedEmpty(PRODUCT_ID)) {
      window.location.href = `/${LEGACY_PRODUCT_ID}`;
      return;
    } else if (IsNullUndefinedEmpty(LEGACY_PRODUCT_ID) && IsNullUndefinedEmpty(PRODUCT_ID)) {
      window.location.href = `/product-not-found`;
      return <ProductNotFound />;
    } else {
      InitCheckout(PRODUCT_ID);
    }
  }, []);

  const updateProductMetrics = async (productId) => {
    try {
      const payload = {
        action: "view",
        origin: "checkout",
        product_id: productId,
        viewer: {
          type: "buyer",
        },
      };
      await dbUpdateProductMetricsPost(productId, payload);

      // Error handling
    } catch (error) {
      // console.log("updateProductMetrics", error);
    }
  };

  const dbUpdateProductMetricsPost = (product_id, payload) => {
    return new Promise((resolve) => {
      try {
        product_metrics_post(product_id, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const AddToAnalytics = (event, payload) => {
    // TODO: Missing error log
    try {
      analytics_create_event_post(event, payload)
        .then((res) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  const dbGetCountriesFiscal = async () => {
    return new Promise((resolve) => {
      try {
        countries_fiscal_get()
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const geolocationResponse = (timeout = 1000) => {
    return new Promise(async (resolve) => {
      try {
        //https://tools.tracemyip.org/search--state/azores-%21-portugal
        const fromLS = loadFromLocalStorage("geolocation");
        if (NotNullUndefinedEmpty(fromLS)) {
          resolve({ status: true, data: fromLS });
          return;
        }

        const countries_response = await dbGetCountriesFiscal();
        let _countries = [];
        if (!countries_response.status) {
          _countries = backupCountries;
          return;
        } else {
          _countries = countries_response.data;
        }
        const res = await axios.get(
          "https://api-bdc.net/data/ip-geolocation?key=bdc_91c931bee9474429a212485f3bf06381",
          // "https://api-bdc.net/data/ip-geolocation?ip=84.23.192.221&key=bdc_91c931bee9474429a212485f3bf06381", //Madeira
          //"https://api-bdc.net/data/ip-geolocation?ip=78.29.167.54&key=bdc_91c931bee9474429a212485f3bf06381", //Azores
          {
            timeout: timeout,
          }
        );

        let _country = _countries.find((c) => c.value === res.data.country.isoAlpha2);
        let _region = _country.regions.find((c) => c.state === res.data.location.principalSubdivision);

        if (_region === undefined) _region = _country.regions[0];

        resolve({ status: true, data: { countries: _countries, country: _country, region: _region } });
        saveOnLocalStorage("geolocation", { countries: _countries, country: _country, region: _region });
      } catch (error) {
        const r = getFallbackGeoLocation();
        resolve({ status: true, data: r, isFallback: true });
      }
    });
  };

  const getFallbackGeoLocation = () => {
    const _countries = backupCountries;
    const _country = _countries.find((c) => c.value === "PT");
    // setSelectedCountry(_country);
    // setSelectedRegion(_country.regions[0]);
    return { countries: _countries, country: _country, region: _country.regions[0] };
  };

  const setDuplicatesCounter = (count) => {
    if (!count) count = 0;

    setDuplicatesSales(count);
  };
  const checkDuplicated = async (payload) => {
    if (!payload) return;
    try {
      setDuplicatesCounter(0);
      action_check_duplicated_post(payload)
        .then((res) => {
          const data = res.data;
          if (data.status) {
            setDuplicatesCounter(data.count || 0);
            //console.log("checkDuplicated", data.count);
          }
        })
        .catch((err) => {
          setDuplicatesCounter(0);
        });

      // Error handling
    } catch (error) {
      setDuplicatesCounter(0);
    }
  };
  const parseCheckEmailError = (data) => {
    //Invalid email
    if (data.code === 100) {
      return {
        message: (
          <>
            O endereço de e-mail parece inválido. Tens a certeza que o endereço de email:{" "}
            <strong>{data.email}</strong> está realmente correto?
          </>
        ),
      };
    }

    //Invalid domain
    if (data.code === 110) {
      return {
        message: (
          <>
            O endereço de e-mail/dominio parece inválido. Tens a certeza que o endereço de email:{" "}
            <strong>{data.email}</strong> está realmente correto?
          </>
        ),
      };
    }

    //Rejected/Email may not exists"
    if (data.code === 120) {
      return {
        message: (
          <>
            Tens a certeza que o endereço de email: <strong>{data.email}</strong> está realmente correto?
          </>
        ),
      };
    }

    // Other cases
    return {
      message: (
        <>
          O endereço de e-mail parece inválido. Tens a certeza que o endereço de email:{" "}
          <strong>{data.email}</strong> está realmente correto?
        </>
      ),
    };
  };

  const checkEmail = (payload) => {
    return new Promise(async (resolve) => {
      try {
        action_check_email_post(payload)
          .then((res) => {
            if (res?.data?.status === false && res?.data?.code >= 100 && res?.data?.code < 500) {
              resolve({ show_notification: true, data: parseCheckEmailError(res.data) });
              return;
            }

            resolve({ show_notification: false, data: res.data });
          })
          .catch((err) => {
            resolve({ show_notification: false, data: err });
            // something went wrong
          });

        // Error handling
      } catch (error) {
        resolve({ show_notification: false, data: error });
        // something went badly wrong
      }
    });
  };

  const addPreCheckoutOptionEvent = (data) => {
    try {
      const payload = {
        affiliate_id: data.affiliate_id,
        purchase_id: data.purchase_id,
        identification: data.identification,
        payment_method: data.payment_method,
        purchase_products: data.purchase_products,
        price_option: data.price_option,
        product_id: data.main_product,
        coupon: data.coupon,
        order_bumps: data.bumps,
        geo: {
          country: data.geo?.country?.value,
          region: data.geo?.region?.value,
        },
      };
      action_pre_checkout_option_post(payload)
        .then((res) => {
          // console.log("PreCheckoutOptions", res.data);
        })
        .catch((err) => {
          //
        });

      // Error handling
    } catch (error) {
      // Error handling
    }
  };

  const addPreCheckoutEvent = (payload) => {
    // TODO: Missing error log
    const precheckout = store("GET", `precheckout-${payload.purchase_id}`, "BOOL");
    if (precheckout) return;
    try {
      action_pre_checkout_post(payload)
        .then((res) => {
          store("SET", `precheckout-${payload.purchase_id}`, "1");

          if (res?.data?.status === "blocked") {
            // store("SET", "blocked-buyer", "1");
            window.location.href = "/something-went-wrong?t=0001";
            return;
          }

          if (res?.data?.status === "blocked-by-producer") {
            window.location.href = "/something-went-wrong?t=0002";
            return;
          }
        })
        .catch((err) => {});

      // Error handling
    } catch (error) {}
  };

  const retryGeoLocation = async (allowed_countries) => {
    try {
      const geo = await geolocationResponse(15000);

      const selCountry = geo.data.country;

      const allowedCountry = validateAllowedCountry(allowed_countries, selCountry?.value);

      if (!allowedCountry) {
        window.location.href = "/invalid-country";
        return;
      }

      // Error handling
    } catch (error) {
      // Ignore
    }
  };

  const InitCheckout = async (product_id, isUpdate = false, _state = undefined) => {
    //console.log("InitCheckout");
    setIsLoading(true);
    try {
      if (_state === undefined || _state === null) _state = state;

      // Prevent multiple calls
      if (isUpdate === false) {
        if (NotNullUndefinedEmpty(productData) || IsNullUndefinedEmpty(product_id)) {
          setIsLoading(false);
          return;
        }
      }

      store("SET", "checks", "0");
      store("SET", "original-url", window.location.href);

      // Check if product is valid
      const product_is_valid_response = await isProductValid(product_id);
      if (!product_is_valid_response.status) {
        InitFailed(false, "Não foi possível obter os dados do produto.");
        setIsLoading(false);
        return;
      }

      // Sales are closed
      if (product_is_valid_response.data.code === 3030) {
        salesClosed(product_is_valid_response.data);
        setIsLoading(false);
        return;
      }

      let selCountry;
      let selRegion;
      let countries;
      let isGeoFallback = false;
      if (!isUpdate) {
        const geo = await geolocationResponse();

        if (geo.isFallback) {
          isGeoFallback = true;
        }
        selCountry = geo.data.country;
        selRegion = geo.data.region;
        countries = geo.data.countries;
      } else {
        selCountry = _state.geo.country;
        selRegion = _state.geo.region;
        countries = _state.countries;
      }

      setCountries(countries);
      //setSelectedCountry(selCountry);
      //setSelectedRegion(selRegion);

      // Get Purchase Id
      let purchaseId = product_is_valid_response.data.purchase_id;
      if (purchaseId === undefined || purchaseId === null) {
        const purchase_id_response = await getPurchaseId(product_id);
        if (!purchase_id_response.status) {
          InitFailed(false, "Não foi possível obter o ID de compra.");
          setErrorType("purchase_id_not_found");
          setIsLoading(false);
          return;
        } else {
          purchaseId = purchase_id_response.data;
        }
      }

      _state = { ..._state, affiliate_id: _AFFILIATE_ID };
      store("SET", "purchase_id", purchaseId);

      // Check if product is in Sandbox mode
      if (product_is_valid_response.data.code === 7001 && product_is_valid_response.data.sandbox === true) {
        activateSandbox(product_is_valid_response.data);
      }

      // Producer not allowed to sell
      if (product_is_valid_response.data.code === 4004 || product_is_valid_response.data.code === 9041) {
        accountSuspended(product_is_valid_response.data);
        setIsLoading(false);
        return;
      }

      // if (product_is_valid_response.data.code === 9041) {
      //   accountSuspended(product_is_valid_response.data);
      //   setIsLoading(false);
      //   return;
      // }
      if (product_is_valid_response.data.code === 9042 || product_is_valid_response.data.code === 9043) {
        accountClosed(product_is_valid_response.data);
        setIsLoading(false);
        return;
      }

      if (product_is_valid_response.data.code === 5309) {
        productSalesClosed(product_is_valid_response.data);
        setIsLoading(false);
        return;
      }

      if (
        product_is_valid_response.data.code === 5850 || //Product status: Rascunho
        product_is_valid_response.data.code === 5860 || //Product status: Em revisão
        product_is_valid_response.data.code === 5870 || //Product status: Ajustes pendentes
        product_is_valid_response.data.code === 5880 || //Product status: Vendas Encerradas
        product_is_valid_response.data.code === 5890 || //Product status: Reprovado
        product_is_valid_response.data.code === 7801 || //Course is not Ready
        product_is_valid_response.data.code === 7902 || //Error loading bundle products
        product_is_valid_response.data.code === 7903 || //Bundle has invalid products
        product_is_valid_response.data.valid === false
      ) {
        productUnavailable(product_is_valid_response.data);
        setIsLoading(false);
        return;
      }

      // const product_response = await loadProduct(PRODUCT_ID, OFFERS, selCountry.value, selRegion.value);
      // if (!product_response.status) {
      //   InitFailed();
      //   return;
      // }

      const product_response = await getProduct(PRODUCT_ID, OFFERS, selCountry.value, selRegion.value, {
        coupon_code: _state.coupon?.coupon_code,
        identification: _state.identification,
        main_product: _state.main_product,
        price_options: _state.price_options,
        purchase_id: _state.purchase_id,
        purchase_products: _state.purchase_products,
        price_option: _state.price_option,
        qty: _state.qty,
        affiliate_id: _state.affiliate_id,
      });

      if (product_response.status === false && product_response?.data?.response?.data?.code === 404) {
        const errorCode = product_response?.data?.response?.data?.error_code;
        if (errorCode === 990012) {
          productOfferNotFound(product_response?.data?.response?.data);
          setIsLoading(false);
          return;
        }
        productNotFound(product_response?.data?.response?.data);
        setIsLoading(false);
        return;
      }

      //console.log("product_response", product_response.data);

      // Check if product is online event
      if (product_response.data.format === "online-event") {
        const eventData = product_response.data.extra.online_event;

        // Check if eventData end_date is after now
        if (moment(eventData.end_date).isBefore(moment())) {
          productSalesClosed({
            message: `O evento '${product_response.data.information.name}' já terminou. Em caso de dúvidas, entra em contato com o produtor.`,
          });
          setIsLoading(false);
          return;
        }
      }

      // Check if product is online event
      if (product_response.data.format === "physical-event") {
        const eventData = product_response.data.extra.physical_event;

        // Check if eventData end_date is after now
        if (moment(eventData.end_date).isBefore(moment())) {
          productSalesClosed({
            message: `O evento '${product_response.data.information.name}' já terminou. Em caso de dúvidas, entra em contato com o produtor.`,
          });
          setIsLoading(false);
          return;
        }
      }

      // if (!product_response.status && product_response.data.code !== 404) {
      //   accountSuspended(product_response.data);
      //   setIsLoading(false);
      //   return;
      // }

      const product = product_response.data;

      if (isGeoFallback === true) {
        retryGeoLocation(product.allowed_countries);
      }

      if (isGeoFallback === false) {
        const allowedCountry = validateAllowedCountry(product.allowed_countries, selCountry?.value);
        if (!allowedCountry) {
          window.location.href = "/invalid-country";
          return;
        }
      }

      setProductData(product);

      const isEvent = product.format === "online-event" || product.format === "physical-event";

      const defaultPriceOption = product.offers[0];

      saveAffiliateId(_AFFILIATE_ID, product_id, _state, product?.affilition);

      let ls = loadLocalData(_state, product);

      if (IsNullUndefinedEmpty(identification.name) === false) {
        ls = {
          ...ls,
          identification: {
            ...ls.identification,
            name: identification.name,
          },
        };
      }

      if (IsNullUndefinedEmpty(identification.email) === false) {
        ls = {
          ...ls,
          identification: {
            ...ls.identification,
            email: identification.email,
          },
        };
      }

      if (IsNullUndefinedEmpty(identification.phone) === false) {
        ls = {
          ...ls,
          identification: {
            ...ls.identification,
            phone: identification.phone,
          },
        };
      }

      if (IsNullUndefinedEmpty(identification.vatid) === false) {
        ls = {
          ...ls,
          identification: {
            ...ls.identification,
            vatid: identification.vatid,
          },
        };
      }

      //let _state = state;
      if (ls !== null) _state = ls;
      _state = { ..._state, price_option: defaultPriceOption, format: product.format };

      if (_COUPON !== "" && _COUPON !== undefined && _COUPON !== null) {
        _state = { ..._state, trigger_coupon: true, temp_coupon: _COUPON };
      } else {
        delete _state.trigger_coupon;
        delete _state.temp_coupon;
      }
      if (_state?.qty === undefined) {
        if (isIntegerNumber(_QTY) === true && isEvent === true) {
          _state = { ..._state, qty: parseInt(_QTY) };
        } else {
          _state = { ..._state, qty: 1 };
        }
      }

      const purchaseProducts = createPurchaseProductsList(product, _state);
      _state = { ..._state, purchase_products: purchaseProducts, purchase_id: purchaseId };

      _state = { ..._state, coupon: product.coupon };
      if (product.coupon) {
        if (product?.coupon?.valid === true) {
          notification.success({
            message: "Cupão aplicado com sucesso",
            description: "O cupão foi aplicado com sucesso.",
          });
        }
        if (product?.coupon?.valid === false) {
          _state = { ..._state, temp_coupon: undefined, trigger_coupon: false };
          notification.warning({
            message: "Oops!",
            description: product?.coupon?.message,
          });
        }
      }

      _state = { ..._state, producer: product.producer };
      _state = { ..._state, main_product: PRODUCT_ID };

      _state = { ..._state, geo: { country: selCountry, region: selRegion } };
      _state = { ..._state, countries: countries };

      let _payment_methods = product.advanced?.payment_methods;

      if (_payment_methods === undefined || _payment_methods === null || _payment_methods?.length === 0) {
        _payment_methods = ["creditcard", "mb", "mbway", "paypal"];
      }
      _state = { ..._state, allowed_methods: _payment_methods };
      _state = { ..._state, ask_vatid_on_checkout: product.advanced?.ask_vatid_on_checkout };

      const _thankyou_page = product.advanced?.thankyou_page;
      _state = { ..._state, thankyou_page: _thankyou_page };

      _state = { ..._state, meta_pixel: product?.meta_pixel };
      _state = { ..._state, ga4_pixel: product?.ga4_pixel };

      _state = { ..._state, producer_terms: product.advanced.producer_terms };

      _state = { ..._state, allow_coupons: product.advanced.allow_coupons };

      _state = { ..._state, customization: product.advanced?.customization };

      if (_state.payment_method === undefined || _state.payment_method === null) {
        _state = { ..._state, payment_method: { method_id: "mbway", name: "MB WAY" } };
      }

      if (
        _state.price_method?.price_method !== "one_time" &&
        ["paypal", "klarna"].includes(_state.payment_method?.method_id)
      ) {
        _state = { ..._state, payment_method: { method_id: "mbway", name: "MB WAY" } };
      }

      _state = { ..._state, allowed_countries: product.allowed_countries };

      setState(_state);
      setIsLoading(false);
      setDataLoaded(true);

      pixelEventPageView(_state);

      updateProductMetrics(PRODUCT_ID);
      AddToAnalytics("pageview", { product_id: PRODUCT_ID, offers: OFFERS, purchase_id: purchaseId });
    } catch (error) {
      setErrorType("error_loading_product");
      setDataLoaded(true);
      setIsLoading(false);
    }
  };

  // After InitCheckout without success
  const InitFailed = () => {
    setErrorType("error_loading_product");
    setDataLoaded(true);
    setIsLoading(false);
    //setErrorType("error_loading_product");
  };

  const productNotFound = (data) => {
    setErrorType("product_not_found");
    setDataLoaded(true);
    setIsLoading(false);
  };

  const productOfferNotFound = (data) => {
    setErrorType("product_offer_not_found");
    setDataLoaded(true);
    setIsLoading(false);
  };

  const productUnavailable = (data) => {
    setErrorType("product_unavailable");
    setDataLoaded(true);
    setIsLoading(false);
  };

  const productSalesClosed = (data) => {
    setErrorType("sales_closed");
    if (data?.message) setErrorTypeMessage(data.message);
    setDataLoaded(true);
    setIsLoading(false);
  };

  const accountSuspended = (data) => {
    setErrorType("account_suspended");
    setDataLoaded(true);
    setIsLoading(false);
  };

  const accountClosed = (data) => {
    setErrorType("account_closed");
    setDataLoaded(true);
    setIsLoading(false);
  };

  const salesClosed = (data) => {
    //   {
    //     "valid": false,
    //     "code": 3030,
    //     "action": "https://www.youtube.com/watch?v=K7Fi7JyAlMs"
    // }

    if (data?.action?.startsWith("http")) {
      window.location.href = data.action;
    } else {
      setErrorType("sales_closed");
      setDataLoaded(true);
      setIsLoading(false);
    }
    return;
  };

  const activateSandbox = (data) => {
    // TODO: Activate sandbox, show message, get api keys for testing...
  };

  const actionHandler = async (name, data) => {
    let _state = state;
    let reloadData = false;

    if (name === "move") {
      setStep(data);
      const _s = movingActionHandler(data, _state);
      setState({ ..._state, ..._s, qty_locked: false });
      return;
    }

    if (name === "update-indentification") {
      if (IsNullUndefinedEmpty(data.name) && IsNullUndefinedEmpty(data.email) && IsNullUndefinedEmpty(data.phone)) {
        return;
      }

      _state = { ..._state, identification: data };
      saveOnLocalStorage("identification", data);
      setState(_state);
      return;
    }

    if (name === "identification") {
      _state = { ..._state, identification: data };
      saveOnLocalStorage(name, data);
      setStep((value) => value + 1);
      AddToAnalytics("precheckout", {
        product_id: PRODUCT_ID,
        qty: _state.qty,
        offers: OFFERS,
        purchase_id: _state.purchase_id,
      });
      pixelEventInitiateCheckout(_state);

      addPreCheckoutEvent({
        product_id: PRODUCT_ID,
        qty: _state.qty,
        offers: OFFERS,
        selected_offer_id: _state.price_option?.offer_id,
        purchase_id: _state.purchase_id,
        identification: data,
        meta_pixel: _state.meta_pixel,
        ga4_pixel: _state.ga4_pixel,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        utm_term: utm_term,
        utm_content: utm_content,
        affiliate_id: _state.affiliate_id,
      });

      // Check Email if customization check_email is enabled
      if (state?.customization?.check_email === true) {
        // Check if email is valid (domain, etc.)
        const checkStatus = await checkEmail({ email: data.email });

        if (checkStatus.show_notification) {
          Modal.confirm({
            title: "Endereço de e-mail está correto?",
            content: checkStatus.data.message,
            okText: "Sim",
            cancelText: "NÃO, quero corrigir",
            onOk() {
              // continue / do nothing
            },
            onCancel() {
              actionHandler("move", 0);
            },
          });
        }
      }
    }

    if (name === "options") {
      const _step = step + 1;
      setStep(_step);
      const _s = movingActionHandler(_step, _state);
      _state = { ..._state, ..._s };
      addPreCheckoutOptionEvent(_state);
    }

    if (name === "price_option") {
      _state = { ..._state, price_option: data };
      saveOnLocalStorage(name, data, productData?.product_id);
      addPreCheckoutOptionEvent(_state);
      reloadData = true;
    }

    if (name === "qty") {
      _state = { ..._state, qty: data };

      // addPreCheckoutOptionEvent(_state);
      // reloadData = false;
    }

    if (name === "payment_method") {
      _state = { ..._state, status: { action: "prepare-payment" } };
      _state = { ..._state, payment_method: data };
      saveOnLocalStorage(name, data, productData?.product_id);
      addPreCheckoutOptionEvent(_state);
    }

    if (name === "update_status") {
      _state = { ..._state, status: data };
      // console.log("STATUS:", data);
    }

    if (name === "qty_locked") {
      _state = { ..._state, qty_locked: data };
    }

    if (name === "geo") {
      const _country = countries.find((c) => c.value === data.country_code);
      const _region = _country.regions.find((r) => r.value === data.region_code);

      _state = { ..._state, geo: { country: _country, region: _region } };
      //setState(_state);
      //await loadProduct(PRODUCT_ID, OFFERS, data.country_code, data.region_code, true);
      // InitCheckout(PRODUCT_ID, true, _state);
      reloadData = true;
      // return;
    }

    if (name === "order-bump-remove-from-cart") {
      let bumps = _state.bumps || [];
      const newBumps = bumps.filter((bump) => bump.product_id !== data.product_id);
      _state = { ..._state, bumps: newBumps };
      addPreCheckoutOptionEvent(_state);
    }

    if (name === "coupon") {
      _state = {
        ..._state,
        trigger_coupon: false,
        temp_coupon: undefined,
        coupon: { coupon_code: data, isLoading: true },
      };
      delete _state.trigger_coupon; // Temporary trigger when coupon is come from URL
      delete _state.temp_coupon; // Temporary coupon when coupon is come from URL

      addPreCheckoutOptionEvent(_state);
      reloadData = true;
      //return;
    }

    if (name === "remove-coupon") {
      //console.log(" remove-coupon _COUPON", _COUPON);
      if (_COUPON !== "") {
        _COUPON = "";
        searchParams.delete("coupon");
        setSearchParams(searchParams);
      }
      _state = { ..._state, coupon: { active: false, temp_coupon: undefined, coupon_code: "", isLoading: true } };
      addPreCheckoutOptionEvent(_state);
      reloadData = true;
    }

    if (name === "order-bump-add-to-cart") {
      let bumps = _state.bumps || [];
      bumps.push(data);

      const unique_products = [...new Map(bumps.map((item) => [item["offer_id"], item])).values()];
      _state = { ..._state, bumps: unique_products };
      addPreCheckoutOptionEvent(_state);
    }

    if (name === "identification" || reloadData === true) {
      checkDuplicated({
        product_id: PRODUCT_ID,
        selected_offer_id: _state.price_option?.offer_id,
        purchase_id: _state.purchase_id,
        identification: _state.identification,
      });
    }

    if (reloadData === true) {
      InitCheckout(PRODUCT_ID, true, _state);
      return;
    }

    // Rebuild PurchaseProducts
    const purchaseProducts = createPurchaseProductsList(productData, _state);

    _state = { ..._state, purchase_products: purchaseProducts };
    setState(_state);

    if (reloadData === true) {
      InitCheckout(PRODUCT_ID, true, _state, false);
    }

    // console.log(purchaseProductsTotal(purchaseProducts), _state.payment_method.method_id);
  };

  const movingActionHandler = (step, _state) => {
    if (step === 1) {
      _state = { ..._state, status: { action: "select-payment" } };
    }
    if (step === 2) {
      _state = { ..._state, status: { action: "prepare-payment" } };
    }

    if (step === 2) {
      pixelEventAddPaymentInfo(_state);
    }
    return _state;
  };

  return (
    <>
      <Helmet>
        <title>{productData?.information?.name || ""} - SalesPark</title>
        {productData?.information?.image && (
          <link id="favicon" rel="icon" type="image/png" href={productData?.information?.image} />
        )}
      </Helmet>

      <Spin spinning={isLoading} size="large">
        <div style={{ height: dataLoaded ? "auto" : "100vh" }}>
          {dataLoaded && errorType === "account_suspended" && <AccountSuspended />}
          {dataLoaded && errorType === "account_closed" && <AccountClosed />}
          {dataLoaded && errorType === "error_loading_product" && <ErrorGettingProduct />}
          {dataLoaded && errorType === "product_not_found" && <ProductNotFound />}
          {dataLoaded && errorType === "product_offer_not_found" && <ProductOFferNotFound />}
          {dataLoaded && errorType === "product_unavailable" && <ProductUnavailable />}
          {dataLoaded && errorType === "sales_closed" && <ProductSalesClosed message={errorTypeMessage} />}
          {dataLoaded && errorType === null && (
            <div className="flex-container" style={{ height: dataLoaded ? "auto" : "100vh" }}>
              <Layout className={`flex-box ${isWidget ? "flex-box-widget" : ""}`}>
                {!isWidget && (
                  <Header style={{ padding: 0, height: "auto" }}>
                    <Banner data={productData?.advanced} />
                  </Header>
                )}

                <Layout style={{ backgroundColor: "white" }}>
                  <SideBar productData={productData} data={state} actionHandler={actionHandler} isMobile={true} />
                  <Content className="main-container">
                    <Row gutter={15}>
                      <Col xs={24} sm={24} md={24} lg={16}>
                        <MainContent
                          duplicatesSales={duplicatesSales}
                          step={step}
                          productData={productData}
                          data={state}
                          actionHandler={actionHandler}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8}>
                        <SideBar
                          productData={productData}
                          data={state}
                          actionHandler={actionHandler}
                          isMobile={false}
                        />
                      </Col>
                    </Row>
                  </Content>
                </Layout>
              </Layout>
            </div>
          )}
        </div>
      </Spin>
    </>
  );
};

export default IndexPage;
