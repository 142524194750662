import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Spin,
  Typography,
  Skeleton,
  Layout,
  Alert,
  Divider,
  Result,
  Modal,
  notification,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Banner from "../../Components/Banner";
import Footer from "../../Components/Footer";
import ccload from "../../assets/images/cc-load.gif";
import { order_get } from "../../api/orders";
import { product_advanced_get } from "../../api/products";
import { retreive_confirm_setup_intent_post, confirmPaymentIntentV4 } from "../../api/payments";
import { IsNullUndefinedEmpty, formatCurrency } from "../../utils/helper";
import { payment_check_get } from "../../api/payments";
import { store } from "../../utils/helper";
import { pixelEventPurchase } from "utils/pixels";
import { loadStripe } from "@stripe/stripe-js";
import { useParams, useSearchParams } from "react-router-dom";

const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const isWidget = window?.location?.hash?.substr(1) === "widget";

const ConfirmPayment = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [loadStatus, setLoadStatus] = useState({ status: true, message: "" });
  const [redirectToken, setRedirectToken] = useState(undefined);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [redirectModalMessage, setRedirectModalMessage] = useState(
    "Estamos só a terminar o teu processo de compra..."
  );
  const [paid, setPaid] = useState(false);
  const [statusAction, setStatusAction] = useState();
  const [paymentStatus, setPaymentStatus] = useState({ title: "A aguardar pagamento..." });
  const [showPurchaseCompleted, setShowPurchaseCompleted] = useState(false);
  let timerCheck;

  let { product_id, purchase_id, payment_unique_id } = useParams();

  const PRODUCT_ID = product_id;
  const PURCHASE_ID = purchase_id;
  const PAYMENT_UNIQUE_ID = payment_unique_id;

  useEffect(() => {
    if (PURCHASE_ID !== undefined && PRODUCT_ID !== undefined) {
      loadData(PRODUCT_ID, PURCHASE_ID, PAYMENT_UNIQUE_ID);
    }
  }, []);

  useEffect(() => {
    if (redirectToken !== undefined) {
      pixelEventPurchase({ ...productData, value: orderData.total });

      clearTimeout(timerCheck);
      const data = productData;
      let customPage = false;

      if (PAYMENT_UNIQUE_ID !== undefined) {
        notification.success({
          message: "Pagamento Concluído",
          description: "O teu pagamento foi concluído com sucesso!",
        });
        setRedirectModalMessage("Feito! Estamos a redirecionar...");
        setTimeout(() => {
          window.location.href = `/installments-payment/${PURCHASE_ID}`;
        }, 2500);
        return;
      }

      let redirect_url = `/billing/${PURCHASE_ID}/?token=${redirectToken}`;
      let redirect_text = "Feito! Estamos a redirecionar para a faturação...";

      if (data?.thankyou_page?.is_external === true && data?.thankyou_page?.url !== undefined) {
        customPage = true;
        redirect_url = data?.thankyou_page?.url;
        redirect_text = "Feito! Estamos a redirecionar para a página de instruções...";
      }

      if (isWidget && !customPage) {
        redirect_text = null;
      }
      const timeout = isWidget ? 1500 : 5000;

      if (redirect_text !== null) setRedirectModalMessage(redirect_text);

      setTimeout(() => {
        // Is normal pageview
        if (!isWidget) {
          window.location.href = redirect_url;
        }

        // Is normal pageview
        if (isWidget) {
          if (customPage) {
            window.top.location.href = redirect_url;
          } else {
            window.location.href = `/${productData?.product_id}/purchase-completed/?token=${redirectToken}`;
          }
        }
      }, timeout);

      // setRedirectModalMessage("Feito! Estamos a redirecionar para a faturação...");
      // setTimeout(() => {
      //   window.location.href = `/billing/${PURCHASE_ID}/?token=${redirectToken}`;
      // }, 5000);
    }
  }, [redirectToken]);

  const loadData = async (product_id, purchase_id, payment_unique_id) => {
    setIsLoading(true);
    setPaymentStatus({ ...paymentStatus, title: "A aguardar pagamento..." });

    const product_response = await loadProduct(product_id);
    setProductData(product_response.data);
    if (!product_response.status || IsNullUndefinedEmpty(product_response.data)) {
      setProductData({ ...product_response.data, status: false });
    }

    const order_response = await loadOrder(product_id, purchase_id, payment_unique_id);
    setOrderData(order_response);
    if (!order_response.status || IsNullUndefinedEmpty(order_response.data)) {
      setOrderData({ ...orderData, status: false });
    }

    const seti_client_secret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");
    const setiParam = new URLSearchParams(window.location.search).get("setup_intent");
    const _payment_intent = new URLSearchParams(window.location.search).get("payment_intent");
    const _payment_intent_client_secret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // const setiResponse = await retreiveSetupIntent(
    //   order_response.data.purchase_id,
    //   order_response.data.payment_unique_id,
    //   setiParam,
    //   seti_client_secret
    // );
    const setiResponse = await confirmPayment({
      purchase_id: order_response.data.purchase_id,
      payment_unique_id: order_response.data.payment_unique_id,
      payment_intent: _payment_intent,
      payment_intent_client_secret: _payment_intent_client_secret,
    });

    // const seti = setiResponse.data;
    // console.log("seti", seti);

    // if (seti.requiresAction) {
    //   const stripe = await loadStripe(seti.publishable_key);
    //   const { error: errorAction, paymentIntent } = await stripe.handleCardAction(seti.clientSecret);
    //   //TODO: https://stripe.com/docs/payments/payment-intents/upgrade-to-handle-actions
    //   // if (paymentIntent) {
    //   const confirm = await confirmSetupIntent(purchase_id, order_response.data.payment_unique_id, seti.reference);
    //   console.log("confirm", confirm.data);
    //   // }
    // }

    // const confirmResponse = await confirmSetupIntent(
    //   order_response.data.purchase_id,
    //   order_response.data.payment_unique_id,
    //   seti.payment_method_id
    // );

    setPaymentStatus({ ...paymentStatus, ...setiResponse.data });
    store("SET", "seti", JSON.stringify(setiResponse.data));

    if (!setiResponse.status || IsNullUndefinedEmpty(setiResponse.data)) {
      setLoadStatus({ status: false, message: "Erro ao tentar recuperar a intenção de pagamento." });
      setPaymentStatus({ ...paymentStatus, title: "Oops! Algo de errado não está certo..." });
    }

    setIsLoading(false);
    initCheckPayment();
    setStatusAction("wait-for-payment");
  };

  const initCheckPayment = async () => {
    // console.log("initCheckPayment");
    try {
      let checks = store("GET", "checks", "INT");
      const d = store("GET", "seti", "OBJ");

      checks++;
      store("SET", "checks", checks);
      clearTimeout(timerCheck);

      timerCheck = setTimeout(() => {
        checkPayment(d);
      }, 5000);
      //
    } catch (error) {
      console.log("initCheckPayment error", error);
    }
  };

  const checkPayment = (data) => {
    
    let isPaid = false;
    let failed = false;
    if (!data?.purchase_id || !data?.method_id || !data?.entity || !data?.reference) {
      initCheckPayment();
      return;
    }
    try {
      payment_check_get(data.purchase_id, data.method_id, data.entity, data.reference)
        .then((response) => {
          // console.log("checkPayment", response.data);
          if (response.data.paid === true) {
            isPaid = true;
            setPaid(true);
            setStatusAction("payment-received");
            if (response.data.token !== undefined) {
              setRedirectToken(response.data.token);
              setShowRedirectModal(true);
              store("DELETE", "purchase_id");
              store("DELETE", `precheckout-${data.purchase_id}`);
              store("DELETE", "checks");
              //store("CLEAR"); // Clear all local storage regarding this purchase
            }
            return;
          }
          if (!IsNullUndefinedEmpty(response.data.pending_reason)) {
            failed = true;
            //authentication_required
            if (response.data.pending_reason !== undefined) {
              setStatusAction("payment-failed");
              setPaymentStatus({
                ...paymentStatus,
                code: response.data.pending_reason,
                message: "Não foi possível confirmar o pagamento. Tenta outro método de pagamento.",
                title: "Não foi possível confirmar o pagamento.",
              });
              setLoadStatus({ status: false, message: "Não foi possível confirmar o pagamento." });
            }
          }

          if (!isPaid && !failed) {
            initCheckPayment();
          }
        })
        .catch((error) => {
          //  console.log("error", error);
          initCheckPayment();
        });
    } catch (error) {
      ///console.log("checkPayment error", error);
      if (isPaid === false) {
        initCheckPayment();
      }
    }
  };

  /****************************************************
   * ##: Retreive Setup Intent
   * @param {String} purchase_id
   * @param {String} payment_unique_id
   * @param {String} setup_intent
   * @param {String} setup_intent_client_secret
   * History:
   * 10-08-2022: Created
   * 30-06-2023: Updated
   ****************************************************/
  const retreiveSetupIntent = async (purchase_id, payment_unique_id, setup_intent, setup_intent_client_secret) => {
    return new Promise((resolve) => {
      try {
        retreive_confirm_setup_intent_post({
          // payment_stripe_intent_confirm_post({
          purchase_id,
          payment_unique_id,
          setup_intent,
          setup_intent_client_secret,
        })
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  /****************************************************
   * ##: Confirm Setup Intent
   * @param {String} purchase_id
   * @param {String} payment_unique_id
   * @param {String} payment_method_id
   * History:
   * 30-06-2024: Created
   ****************************************************/
  const confirmPayment = async (payload) => {
    return new Promise((resolve) => {
      try {
        confirmPaymentIntentV4(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const loadProduct = async (product_id) => {
    return new Promise((resolve) => {
      try {
        product_advanced_get(product_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const loadOrder = async (product_id, purchase_id, payment_unique_id) => {
    return new Promise((resolve) => {
      try {
        order_get(product_id, purchase_id, payment_unique_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  // const confirmPayment = async () => {
  //   // // Intent Confirm
  //   // const intent_confirm = paymentIntentConfirm({
  //   //   identification: data.identification,
  //   //   payment_unique_id: stripeData.payment_unique_id,
  //   //   //confirm_response: confirm_reponse.data,
  //   // });
  //   // if (!intent_confirm.status) {
  //   //   // Ignore result... Data stills in Stripe Payment payload
  //   // }
  // };
  const redirectToCheckout = () => {
    setIsLoading(true);
    const original_url = store("GET", "original_url", "STRING");
    if (original_url !== undefined && original_url !== null && original_url !== "") {
      window.location = original_url;
      return;
    }
    window.location = `${window.location.origin}/${PRODUCT_ID}`;
  };

  const ShowLoadError = () => {
    const desc = (
      <>
        {loadStatus.message}{" "}
        <Button type="link" style={{ padding: "4px 0px" }} onClick={() => window.location.reload()}>
          Tentar novamente.
        </Button>
      </>
    );
    return (
      <div className="alerts">
        <Alert message="Ocorreu um erro!" description={desc} type="error" showIcon />
        <div style={{ display: "flex", justifyContent: "center", marginTop: 25 }}>
          <Button onClick={() => redirectToCheckout()} size="large" type="primary">
            Tentar outro método de pagamento
          </Button>
        </div>
      </div>
    );
  };
  const ProductError = () => {
    if (!productData) return <></>;
    if (productData.status === false) {
      return (
        <div className="alerts">
          <Alert
            message="Oops!"
            description="Ocorreu um erro ao carregar os dados do produto..."
            type="error"
            showIcon
          />
        </div>
      );
    } else {
      return <></>;
    }
  };
  const OrderError = () => {
    if (!orderData) return <></>;
    if (orderData.status === false) {
      return (
        <div className="alerts">
          <Alert
            message="Oops!"
            description="Ocorreu um erro ao carregar dados da encomenda..."
            type="error"
            showIcon
          />
        </div>
      );
    } else {
      return <></>;
    }
  };
  const PaymentStatus = ({ paid, response }) => {
    if (!response) return <></>;

    if (paid === true) {
      return (
        <Result
          status="success"
          title="Pagamento Concluído"
          subTitle="Parabéns, a tua compra foi efetuada com sucesso!"
        />
      );
    }

    if (response.code === "succeeded") {
      return <Alert message="Está quase..." description={response.message} type="info" showIcon />;
    } else if (response.code === "authentication_required") {
      return <Alert message="Oops" description={response.message} type="error" showIcon />;
    } else if (response.code === "processing") {
      return <Alert message="Aguarde..." description={response.message} type="warning" showIcon />;
    } else if (response.code === "requires_payment_method") {
      return <Alert message="Aguarde..." description={response.message} type="error" showIcon />;
    } else {
      return <></>;
    }
  };
  const ProductsPurchased = ({ products, purchase_id }) => {
    if (!products) return <></>;
    if (products.length === 0) return <></>;

    let total = products.reduce((acc, curr) => acc + curr.price, 0);
    total = parseFloat(total.toFixed(2));
    const _products = products.map((product, index) => {
      return (
        <div key={index}>
          <Paragraph type="secondary">
            <small>
              {" "}
              ➜ <strong>{product.name}</strong> - {product.n_payments} x {formatCurrency(product.price)}
            </small>
          </Paragraph>
        </div>
      );
    });
    return (
      <>
        <Paragraph type="secondary">
          <strong>Id da Transação:</strong> {purchase_id}
        </Paragraph>
        <Paragraph type="secondary">
          <strong>Produtos:</strong> {_products}
        </Paragraph>

        <Paragraph type="secondary">
          <strong>Total da Compra:</strong> {formatCurrency(total)}
        </Paragraph>
      </>
    );
  };

  const PersonalData = ({ data }) => {
    if (!data) return <></>;
    return (
      <>
        <Paragraph type="secondary">
          <strong>Nome:</strong> {data.name}
        </Paragraph>
        <Paragraph type="secondary">
          <strong>E-mail:</strong> {data.email}
        </Paragraph>
        <Paragraph type="secondary">
          <strong>Telefone:</strong> {data.phone}
        </Paragraph>
      </>
    );
  };

  return (
    <div style={{ height: "auto" }}>
      <div className="flex-container" style={{ height: "auto" }}>
        <Layout className="flex-box-small">
          <Header style={{ padding: 0, height: "auto" }}>
            <Banner data={productData?.advanced} />
          </Header>

          <Layout style={{ backgroundColor: "white" }}>
            <Content className="waiting-payment-main-container">
              <Card className="confirm-payment-container-card">
                <div className="waiting-payment-container">
                  {!paid && (
                    <div className="title">
                      <Title level={3}>
                        {!paid && loadStatus?.status === true && (
                          <>
                            <LoadingOutlined />
                            &nbsp;&nbsp;
                          </>
                        )}
                        {paymentStatus?.title}
                      </Title>
                    </div>
                  )}
                  <Spin spinning={isLoading} size="large">
                    {isLoading && (
                      <>
                        <Skeleton active />
                        <div style={{ textAlign: "center" }}>
                          <img alt="" src={ccload} />
                        </div>
                        <Skeleton active />
                      </>
                    )}

                    {!isLoading && loadStatus?.status === true && (
                      <>
                        <div className="payment-status">
                          <PaymentStatus paid={paid} response={paymentStatus} />
                        </div>
                        <div className="order-details">
                          <Card>
                            <Title level={5}>Os teus dados</Title>
                            <PersonalData data={orderData?.data?.buyer} />

                            <Divider dashed />

                            <Title level={5}>Detalhes da Compra</Title>
                            <ProductsPurchased
                              products={orderData?.data?.products}
                              purchase_id={orderData?.data?.purchase_id}
                            />
                          </Card>
                        </div>
                      </>
                    )}
                    {loadStatus?.status === false && <ShowLoadError />}
                    <OrderError />
                    <ProductError />
                  </Spin>
                </div>
              </Card>
              <Footer />
            </Content>
          </Layout>
        </Layout>
      </div>
      <Modal
        closable={false}
        title="Pagamento Concluído!"
        visible={showRedirectModal}
        okText="Está quase..."
        footer={null}
      >
        <p>
          <LoadingOutlined />
          &nbsp;&nbsp;&nbsp;{redirectModalMessage}
        </p>
      </Modal>
    </div>
  );
};

export default ConfirmPayment;
