import React, { useState, useEffect } from "react";
import { Drawer, Divider, Button, Spin } from "antd";

import { BillingDataFields } from "./BillingFormHelper";

import PurchaseInfo from "./PurchaseInfo";
import PurchaseDetails from "./PurchaseDetails";
import BillingDrawer from "./BillingDrawer";
import useMediaQuery from "use-media-antd-query";

const DetailsDrawer = ({
  visible,
  close,
  billing,
  buyerData,
  salesData,
  refundRequest,
  cancelFuturePaymentys,
  token,
  purchaseId,
  reloadData,
  isLoading,
}) => {
  const [billingDrawer, setBillingDrawer] = useState({ visible: false });
  const colSize = useMediaQuery();
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);

  return (
    <>
      <Drawer visible={visible} onClose={close} width={isMobileSize ? "100%" : 600}>
        <Spin spinning={isLoading}>
          <div>
            <div style={{ textAlign: "center" }}>
              <h2>Detalhes da Compra</h2>
            </div>
            <Divider dashed />

            <div className="purchase-details">
              <h3>Dados de Compra</h3>
              <PurchaseInfo buyerData={buyerData} salesData={salesData} />

              <Divider dashed />
            </div>

            {billing && (
              <div className="billing-details">
                <h3>Dados de Faturação</h3>
                <div className="billing-info-list">
                  <BillingDataFields fieldTitle="Nome" fieldValue={billing.name} />
                  <BillingDataFields fieldTitle="Contribuinte" fieldValue={billing.vatid} />
                  <BillingDataFields fieldTitle="E-mail" fieldValue={billing.email} />
                  <BillingDataFields fieldTitle="Telefone" fieldValue={billing.phone} />
                  <BillingDataFields fieldTitle="Morada" fieldValue={billing.address} />
                  <BillingDataFields fieldTitle="Cidade" fieldValue={billing.city} />
                  <BillingDataFields fieldTitle="Código Postal" fieldValue={billing.postal_code} />
                  <BillingDataFields fieldTitle="País" fieldValue={billing.country} />
                </div>
                {salesData?.billing_data_completed === true && (
                  <>
                    {(salesData?.is_recurrence === true || salesData?.is_installment === true) && (
                      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
                        <Button
                          disabled={billingDrawer.visible === true}
                          type="primary"
                          onClick={() =>
                            setBillingDrawer({ visible: true, billing, sale: salesData, buyer: buyerData })
                          }
                        >
                          Alterar Dados de Faturação
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <PurchaseDetails
              salesData={salesData}
              refundRequest={refundRequest}
              cancelFuturePaymentys={cancelFuturePaymentys}
            />
          </div>
        </Spin>
        <BillingDrawer
          visible={billingDrawer.visible}
          billing={billingDrawer.billing}
          token={token}
          purchaseId={purchaseId}
          close={(reload) => {
            setBillingDrawer({ visible: false });
            if (reload) reloadData();
          }}
        />
      </Drawer>
    </>
  );
};

export default DetailsDrawer;
